.canNotVoteButton {
  &:disabled {
    opacity: 1;
  }
}

.wrapper {
  .edClipboardButton {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    padding: 0px 0px;
    text-align: center;
    font-size: 14px;
    border: 1px solid white;
  }

  .edClipboardButton:focus {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    padding: 0px 0px;
    text-align: center;
    font-size: 14px;
    border: 1px solid white;
  }

  .edClipboardButton:hover {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    padding: 0px 0px;
    text-align: center;
    font-size: 14px;
    border: 1px solid white;
  }
}